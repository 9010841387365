<template>
  <div>
    <div class="map">
      <div class="container">
        <h3 class="map-title">{{ $t("buttons.based") }}</h3>
      </div>
      <div class="container reverse">
        <!-- <h3 class="map-title">Мы находимся</h3> -->
        <div class="map-title__mobile">
          <select
            name="select"
            class="mobile-select"
            @change="MapsMobile"
            v-model="selected"
          >
            <option
              v-for="map in getMap"
              :value="map"
             
              :key="map"
              v-html="map.translate"
            ></option>
          </select>
          <p>{{ $t("modal.city") }}</p>
        </div>
        <div class="map-buttons" role="group" id="toolBtns">
          <button
            :class="{ activeButton: i === IsActive }"
            v-for="(map, i) in getMap"
            :value="mapContent"
            :key="map.id"
            @click="Maps(map), IsActiveButton(i)"
            v-html="map.translate"
          ></button>
        </div>
        <div class="map-address" v-if="asd">
          <div class="map-address__left m_none">
            <iframe
              :src="googleMap"
              width="980"
              height="345"
              style="border: 0"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
          <div class="map-add">
            <button
              class="map-add-btn"
              v-for="md in mapData"
              :key="md"
              @click="changePlace(md)"
            >
              <div class="map-address__right">
                <span
                  class="map-address__right__city"
                  v-html="md.title"
                ></span>
                <h5
                  class="map-address__right__street"
                  v-html="md.description"
                ></h5>
                <h5 class="map-address__right__tel" v-html="md.phone"></h5>
              </div>
              <br />
            </button>
            <div class="map-address__right__btn">
              <button id="click2" @click="isOpen = true, this.$refs.myForm.resetForm()">
                <h2>{{ $t("buttons.call") }}</h2>
              </button>
            </div>
          </div>
        </div>
        <div class="map-address" v-else>
          <div class="map-address__left m_none">
            <iframe
              :src="stateButton"
              width="980"
              height="345"
              style="border: 0"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
          <div class="map-add">
            <button class="map-add-btn">
              <div class="map-address__right">
                <span
                  class="map-address__right__city"
                  v-html="stateButton2"
                ></span>
                <h5
                  class="map-address__right__street"
                  v-html="stateButton3"
                ></h5>
                <h5 class="map-address__right__tel" v-html="stateButton4"></h5>
              </div>
              <br />
              <!-- <hr /> -->
            </button>
            <div class="map-address__right__btn">
              <button id="click2" @click="isOpen = true, this.$refs.myForm.resetForm()">
                <h2>{{ $t("buttons.call") }}</h2>
              </button>
            </div>
          </div>
        </div>
        <div
          :class="{ showButton: i === IsShow }"
          class="map-address__left d_none display_none"
        >
          <iframe
          v-if="asd"
              :src="googleMap"
              width="980"
              height="345"
              style="border: 0"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          <iframe
          v-else
            :src="stateButton"
            width="980"
            height="345"
            style="border: 0"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
        <div class="map-btn show-map" v-if="IsShow == false">
          <button @click="IsActiveMap(i)">
            <h2>{{ $t("buttons.showMap") }}</h2>
          </button>
        </div>
        <div class="map-btn">
          <button @click="isOpen = true">
            <h2>{{ $t("buttons.call") }}</h2>
          </button>
        </div>
        <modal :open="isOpen" @close="isOpen = !isOpen">
          <feedback />
          <Form ref="myForm" @submit="Send">
          <div class="modal-inputs">
            <div class="err-message">
              <Field
                v-model="GuestName"
                name="username"
                type="text"
                :placeholder="$t('placeholder.name')"
                :rules="requiredName"
                @keyup="onlyText()"
              />
              <div :class="{ remove: IsRemove }" class="">
                <ErrorMessage name="username" />
              </div>
            </div>
            <!-- <input v-model="GuestName" type="text" :placeholder="$t('placeholder.name')" /> -->
            <div class="err-message">
              <Field
                v-mask="'+7(7##)-###-##-##'"
                v-model="GuestPhone"
                type="text"
                name="phone"
                :placeholder="$t('placeholder.phone')"
                :rules="requiredNum"
              />
              <div :class="{ remove: IsRemove }" class="">
                <ErrorMessage name="phone" />
              </div>
            </div>
            <div class="err-message">
              <Field
                v-model="organization_name"
                type="text"
                :placeholder="$t('placeholder.company')"
                name="organization"
                class="name"
                :rules="requiredNum"
              />
              <div :class="{ remove: IsRemove }" class="">
                <ErrorMessage name="organization" />
              </div>
            </div>
            <textarea
              v-model="guest_desc"
              :placeholder="$t('placeholder.com')"
            ></textarea>
          </div>
          <div class="modal-check">
            <p class="modal-check-title">{{ $t("modal.type") }}</p>
            <div class="check">
              <select v-model="product_name" name="" id="">
                <option v-for="pr in product" :key="pr">
                  {{ pr.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="modal-btn">
            <button
              type="submit"
              :class="{isDisabled : disabled}"
              @click="buttonClicked"
            >
              <div
                v-if="loading"
                class="spinner-border spinner-border-sm"
              ></div>
              {{ $t("buttons.send") }}
            </button>
          </div>
          <div class="modal-rule">
            <input type="checkbox" id="checkk" v-model="checked" />
            <label for="checkk"
              >{{ $t("modal.check1") }}
              <a href="/condition">{{ $t("modal.check2") }}</a></label
            >
          </div>
        </Form>
        </modal>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import modal from "../components/windows/modal.vue";
import axios from "axios";
import { Buffer, resolveObjectURL } from "buffer";
import { Field, Form, ErrorMessage } from "vee-validate";
import { createToaster } from "@meforma/vue-toaster";
import feedback from "@/components/feedback.vue";
// import VueLoadingButton from "vue-loading-button";
const toaster = createToaster({ position: "top-right", closeOnClick: true });
export default {
  components: { modal, feedback, Field, Form, ErrorMessage },
  setup() {
    const isOpen = ref(false);
    return { isOpen };
  },
  data() {
    return {
      IsActive: false,
      IsShow: false,
      loading: false,
      disabled: false,
      getMap: [],
      mapData: [],
      TestMap: null,
      asd: null,
      stateMapData: [],
      stateMapAddress: null,
      selected: "",
      GuestName: null,
      GuestPhone: null,
      // GuestEmail: null,
      organization_name: null,
      resp: [],
      googleMap: null,
      stateCity: 2,
      stateButton: null,
      stateButton2: null,
      stateButton3: null,
      stateButton4: null,
      checked: false,
      guest_desc: null,
      product: null,
      IsRemove: false,
      product: [
        { name: this.$t("product.med") },
        { name: this.$t("product.kasko") },
        { name: this.$t("product.cargo") },
        { name: this.$t("product.property") },
        { name: this.$t("product.gpo") },
        { name: this.$t("product.law") },
        { name: this.$t("product.ns") },
        { name: this.$t("product.other") },
      ],
      hexTokens: {
        Y: { pattern: /[0-9]/ },
        "#": { pattern: /\d/ },
        X: { pattern: /[0-9a-zA-Z]/ },
        // 'S': {pattern: /[А-Яа-я]/},
        S: { pattern: /[a-zA-Z]/ },
        A: { pattern: /[a-zA-Z0-9]/, transform: (v) => v.toLocaleUpperCase() },
        a: { pattern: /[a-zA-Z]/, transform: (v) => v.toLocaleLowerCase() },
        "!": { escape: true },
        F: {
          pattern: /[0-9ЁёА-я]/,
        },
        // 'N': {pattern: /[a-zA-ZА-Яа-я]/},
      },
    };
  },
  mounted() {
    this.stateButtonFn();

  },
  created() {
    axios
      .get("V1/cities")
      .then((response) => {
        this.getMap = response.data.data;
        this.stateCity = response.data.data[0].id;
        this.selected = response.data.data[18];

      })
      .catch((e) => {
        this.errors.push(e);
      });
  },
  methods: {
    onlyText() {
      this.GuestName = this.GuestName.replace(
        /[^а-яА-ЯёЁәӘіІңҢғҒүҮұҰқҚөӨһҺa-zA-Z\-\s]/gi,
        ""
      );
    },
    buttonClicked() {
      this.disabled = !false;
      // alert("clicked!");
      setTimeout(() => (this.disabled = !true), 5000);
    },
    validateEmail(value) {
      if (!value) {
        return this.$t("placeholder.req");
      }
      const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
      if (!regex.test(value)) {
        return this.$t("placeholder.reqEmail");
      }
      //All is good
      return true;
    },
    requiredName(value) {
      if (!value) {
        return this.$t("placeholder.req");
      }
      //All is good
      return true;
    },
    requiredNum(value) {
      if (!value) {
        return this.$t("placeholder.req");
      }
      //All is good
      return true;
    },
    IsActiveButton(i) {
      this.IsActive = i;
    },
    IsActiveMap(i) {
      this.IsShow = i;
      // console.log("asdasd");
    },
    changePlace(md) {
      this.googleMap = md.url;
    },
    MapsMobile() {
      this.asd = "1";
      axios
        .get("V1/addresses", { params: { city_id: this.selected.id } })
        .then((response) => {
          this.mapData = response.data.data;
          this.googleMap = response.data.data[0].url;
        })
        .catch((e) => {
          this.errors.push(e);
        });
      // console.log("basildy", map.id);
    },
    stateButtonFn() {
      console.log(2);
      console.log(this.stateCity);
      axios
        .get("V1/addresses", { params: { city_id: this.stateCity } })
        .then((response) => {
          this.stateButton = response.data.data[0].url;
          this.stateButton2 = response.data.data[0].title;
          this.stateButton3 = response.data.data[0].description;
          this.stateButton4 = response.data.data[0].phone;
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
    Maps(map) {
      this.asd = "1";
      axios
        .get("V1/addresses", { params: { city_id: map.id } })
        .then((response) => {
          this.mapData = response.data.data;
          this.googleMap = response.data.data[0].url;
        })
        .catch((e) => {
          this.errors.push(e);
        });
      this.TestMap = map.translate;
      // console.log("basildy", map.id);
    },
    Send(values, {resetForm}) {
      console.log(values);
      if (this.checked === true) {
        const auth = new Buffer("Nomad:Qwerty123", "utf-8").toString("base64");
        this.form = {
          username: this.GuestName,
          form_name: "callback",
          organization_name: this.organization_name,
          phone: this.GuestPhone,
          description: this.guest_desc,
          product_name: this.product_name,
        };
        axios
          .post("https://bitrix24.cic.kz/api/crm/leads/create/", this.form, {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Content-type": "application/json",
              Authorization: "Basic " + auth,
            },
          })
          .then((res) => {
            if (res.data.status == true) {
              this.loading = !false;
              setTimeout(() => (this.loading = !true), 3000);
              toaster.success(this.$t('notification.success'));
              this.disabled = true;
              resetForm();
              setTimeout(()=> (this.isOpen = false),500);
            } else {
              toaster.error(
                this.$t('notification.reqProduct')
              );
            }
          })
          .catch((err) => {
            console.log(err);
            // console.log(auth);
          });
      } else {
        toaster.error(
          this.$t('notification.dontagree')
        );
      }
    },
  },
  watch: {
    isOpen: function () {
      if (this.isOpen) {
        document.documentElement.style.overflow = "hidden";
        return;
      }
      document.documentElement.style.overflow = "auto";
      this.GuestName = "";
      // this.GuestEmail = "";
      this.organization_name = "",
      this.GuestPhone = "";
      this.guest_desc = "";
      this.product_name = "";
      this.checked = false,
      this.IsRemove = true;
    },
  },
};
</script>

<style lang="scss" scoped>
@mixin fontStyle($family, $style, $weight, $size, $lheight, $color) {
  font-family: $family;
  font-style: normal;
  font-weight: $weight;
  font-size: $size;
  line-height: $lheight;
  color: $color;
}
@mixin mobile {
  @media (min-width: 320px) and (max-width: 425px) {
    @content;
  }
}
@mixin tablet {
  @media (min-width: 425px) and (max-width: 768px) {
    @content;
  }
}
@mixin tablet-1024 {
  @media (min-width: 768px) and (max-width: 1024px) {
    @content;
  }
}
@mixin desktop {
  @media (min-width: 1024px) and (max-width: 1440px) {
    @content;
  }
}
.err-message {
  display: block;
  span {
    @include fontStyle(Roboto, normal, 400, 16px, 20px, red);
    padding-top: 5px;
  }
  @include tablet-1024() {
    span {
      font-size: 8px;
      line-height: 12px;
    }
  }
  @include desktop() {
    span {
      font-size: 12px;
      line-height: 14px;
    }
  }
}
.showButton {
  display: block !important;
}
.show-map {
  margin-top: -10px !important;
}
.container {
  @include mobile() {
    width: 90%;
  }
}
.display_none {
  display: none;
}
.reverse {
  display: flex;
  flex-direction: column-reverse;
  @include mobile() {
    flex-direction: column;
  }
  @include tablet() {
    flex-direction: column;
  }
}
.isDisabled {
  opacity: 0.2;
  backdrop-filter: sepia(90%);
}
.disabled {
  opacity: 0.6 !important;
}
p {
  margin-bottom: 0rem !important;
}
.map-buttons {
  p {
    margin-bottom: unset !important;
  }
}
.mobile {
  &-select {
    @include fontStyle(Roboto, normal, 300, 12px, 14px, #373737);
    margin-bottom: 8px;
    border: none;
    background-color: #fff;
    border-radius: 3px;
    padding: 6px 10px;
    :focus-visible {
      border: none;
    }
  }
  &-opt {
    padding: 2px;
    font-size: 10px;
    line-height: 12px;
  }
  @include tablet() {
    &-select {
      font-size: 14px;
    }
  }
}
.map {
  &-add {
    &-btn {
      margin-right: 20px;
      @include mobile() {
        width: 100%;
      }
      @include tablet() {
        width: 100%;
      }
    }
  }
}
.activeButton {
  background: linear-gradient(
    90deg,
    #ff4466 0%,
    #be31ce 49.97%,
    #3641ff 100%
  ) !important;
  border-radius: 7px !important;
  color: #ffffff !important;
}
.map {
  padding-top: 106px;
  padding-bottom: 120px;
  background: #efefef;
  &-add {
    width: 100%;
    &-btn {
      border: none;
      text-align: left;
    }
    button {
      border: none;
      background: transparent;
    }
  }
  &-btn {
    display: none;
    button {
      h2 {
        @include mobile() {
          font-size: 9px;
          margin-bottom: 0px;
        }
        @include tablet() {
          font-size: 10px;
          margin-bottom: 0;
        }
        @include tablet-1024() {
          font-size: 10px;
        }
        @include desktop() {
          font-size: 14px;
        }
      }
    }
  }
  &-title {
    @include fontStyle(Roboto, normal, 500, 36px, 43px, #373737);
    letter-spacing: 0.03em;
    text-transform: uppercase;
    // background: linear-gradient(90deg, #FF143F 0%, #C600DD 20%, #1B27FF 27%);
    // -webkit-background-clip: text;
    // -webkit-text-fill-color: transparent;
    // background-clip: text;
    padding-bottom: 40px;
    &__mobile {
      display: none;
      @include fontStyle(Roboto, normal, 300, 10px, 12px, #373737);
      p {
        @include fontStyle(Roboto, normal, 300, 12px, 14px, #373737);
        margin-left: 10px;
      }
    }
  }
  &-buttons {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 0px;
    width: 106%;
    button {
      background: #ffffff;
      border-radius: 7px;
      width: 130px;
      height: 50px;
      @include fontStyle(Roboto, normal, 300, 14px, 19px, #000000);
      text-align: center;
      border: none;
      margin-right: 40px;
      margin-bottom: 18px;
      p {
        margin-bottom: 0rem !important;
      }
      &:hover,
      &:active {
        background: linear-gradient(
          90deg,
          #ff4466 0%,
          #be31ce 49.97%,
          #3641ff 100%
        );
        border-radius: 7px;
        color: #ffffff;
      }
    }
  }
  &-address {
    display: flex;
    justify-content: space-between;
    margin-bottom: 37px;
    &__right {
      margin-left: 20px;
      &__city {
        @include fontStyle(Roboto, normal, 400, 18px, 0px, #2e3236);
      }
      &__street {
        padding-top: 15px;
        padding-bottom: 15px;
        @include fontStyle(Roboto, normal, 300, 18px, 21px, #373737);
      }
      &__tel {
        @include fontStyle(Roboto, normal, 300, 18px, 21px, #373737);
      }
      &__btn {
        // margin-top: 116px;
        background: linear-gradient(
            0deg,
            rgba(255, 255, 255, 0.2),
            rgba(255, 255, 255, 0.2)
          ),
          #ffffff;
        mix-blend-mode: normal;
        border-radius: 15px;
        width: 300px;
        height: 61px;
        margin-left: 20px;
        &:hover {
          background: linear-gradient(
            90deg,
            #ff4466 0%,
            #be31ce 49.97%,
            #3641ff 100%
          );
          color: #ffffff;
          button {
            -webkit-background-clip: text;
            -webkit-text-fill-color: #ffffff;
            background-clip: text;
          }
        }
        button {
          @include fontStyle(Roboto, normal, 600, 18px, 25px, #373737);
          color: #8948b1;
          width: 300px;
          height: 61px;
          border: none;
          h2 {
            font-size: 18px;
            margin-bottom: 0;
            @include mobile() {
              font-size: 9px;
            }
            @include tablet() {
              font-size: 10px;
            }
            @include tablet-1024() {
              font-size: 10px;
            }
            @include desktop() {
              font-size: 16px;
            }
          }
        }
      }
    }
    &-left {
      iframe {
        width: 980px;
        height: 345px;
      }
    }
  }
  @include mobile() {
    padding-top: 40px;
    padding-bottom: 40px;
    &-title {
      font-size: 14px;
      line-height: 14px;
      padding-bottom: 3px;
      background: linear-gradient(
        90deg,
        #ff143f 0%,
        #c600dd 15.97%,
        #1b27ff 34%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      &__mobile {
        display: flex;
        align-items: baseline;
        // justify-content: flex-end;
      }
    }
    &-btn {
      display: block;
      text-align: center;
      border-radius: 3px;
      background-color: #ffffff;
      width: 100%;
      margin: 0 auto;
      margin-top: 10px;
      button {
        border-radius: 3px;
        width: 100%;
        height: 45px;
        @include fontStyle(Roboto, normal, 600, 9px, 12px, #8948b1);
        border: none;
        background: #ffffff;
        h2 {
          font-size: 14px;
        }
      }
      &:hover {
        background: linear-gradient(
          90deg,
          #ff4466 0%,
          #be31ce 49.97%,
          #3641ff 100%
        );
        color: #ffffff;
        button {
          -webkit-background-clip: text;
          -webkit-text-fill-color: #ffffff;
          background-clip: text;
        }
      }
    }
    &-buttons {
      display: none;
    }
    &-address {
      flex-direction: column-reverse;
      margin-bottom: 10px;
      &__left {
        iframe {
          width: 100%;
          height: 180px;
        }
      }
      &__right {
        margin-left: 0;
        &__btn {
          display: none;
        }
        &__city {
          display: none;
        }
        &__street {
          //change
          font-size: 12px;
          line-height: 15px;
          padding-top: 0;
          padding-bottom: 0;
          p {
            margin-bottom: 0 !important;
          }
          span {
            display: none;
          }
        }
        &__tel {
          padding-top: 10px;
          font-size: 12px;
          line-height: 12px;
          margin-bottom: 0;
        }
      }
    }
  }
  @include tablet() {
    padding-top: 50px;
    padding-bottom: 50px;
    &-title {
      background: linear-gradient(
        90deg,
        #ff143f 0%,
        #c600dd 15.97%,
        #1b27ff 34%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      font-size: 16px;
      line-height: 16px;
      padding-bottom: 10px;
      &__mobile {
        display: flex;
        align-items: baseline;
        // justify-content: flex-end;
      }
    }
    &-add {
      width: 100%;
    }
    &-btn {
      display: block;
      text-align: center;
      border-radius: 3px;
      background-color: #ffffff;
      width: 100%;
      margin: 0 auto;
      margin-top: 10px;
      button {
        border-radius: 3px;
        width: 100%;
        height: 47px;
        @include fontStyle(Roboto, normal, 600, 9px, 12px, #8948b1);
        border: none;
        background: #ffffff;
        h2 {
          font-size: 16px;
        }
      }
      &:hover {
        background: linear-gradient(
          90deg,
          #ff4466 0%,
          #be31ce 49.97%,
          #3641ff 100%
        );
        color: #ffffff;
        button {
          -webkit-background-clip: text;
          -webkit-text-fill-color: #ffffff;
          background-clip: text;
        }
      }
    }
    &-buttons {
      display: none;
    }
    &-address {
      flex-direction: column-reverse;
      &__left {
        width: 100%;
        iframe {
          width: 100%;
          // width: clamp(200px, 80%, 380px);
          // height: clamp(100px,100%, 180px);
        }
      }
      &__right {
        width: 100%;
        margin-left: 0;
        &__btn {
          display: none;
        }
        &__city {
          display: none;
        }
        &__street {
          //change
          font-size: 14px;
          line-height: 15px;
          padding-top: 0;
          padding-bottom: 0;
          span {
            display: none;
          }
        }
        &__tel {
          padding-top: 10px;
          font-size: 14px;
          line-height: 15px;
        }
      }
    }
  }
  @include tablet-1024() {
    &-address {
      &__left {
        iframe {
          width: 425px;
        }
      }
      &__right {
        width: 200px;
        &__city {
          font-size: 14px;
          line-height: 16px;
        }
        &__street {
          font-size: 15px;
          line-height: 17px;
        }
        &__tel {
          font-size: 12px;
          line-height: 14px;
        }
        &__btn {
          width: 150px;
          height: 50px;
          font-size: 10px;
          button {
            width: 150px;
            height: 50px;
            font-size: 10px !important;
          }
        }
      }
    }
  }
  @include desktop() {
    &-address {
      padding-top: 20px;
      &__left {
        iframe {
          width: 500px;
        }
      }
    }
  }
  @media (min-width: 1440px) and (max-width: 1600px) {
    &-buttons {
      button {
        margin-right: 30px;
      }
    }
    &-address {
      &__right {
        &__btn {
          width: 250px;
          button {
            width: 250px;
            h2 {
              font-size: 16px;
            }
          }
        }
      }
    }
  }
}
</style>
