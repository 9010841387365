<template>
    <div class="instructions_content">
      <div class="instructions">
        <h2>{{ $t("medicinePulInstructions.title") }}</h2>
        <div class="docs">
          <div v-for="(doc, index) in documents" :key="index">
            <a :href="doc.path" :download="doc.filename">
              <img src="./../assets/images/file-pdf-regular.svg" alt="doc">{{ doc.name }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </template>
  <script>

  export default {
    data() {
      return {
        documents: [
          {
            name: this.$t("medicinePulInstructions.terminationContract"),
            path: "/instructions/terminationContract.pdf",
            filename: this.$t("medicinePulInstructions.terminationContract"),
            icon: './../assets/images/file-pdf-regular.svg'
          },
          {
            name: this.$t("medicinePulInstructions.CreatingContract"),
            path: "/instructions/CreatingContract.pdf",
            filename: this.$t("medicinePulInstructions.CreatingContract"),
            icon: "./../assets/images/file-pdf-regular.svg",
          },
          {
            name: this.$t("medicinePulInstructions.CreatingAdditionalAgreement"),
            path: "/instructions/CreatingAdditionalAgreement.pdf",
            filename: this.$t("medicinePulInstructions.CreatingAdditionalAgreement"),
            icon: "./../assets/images/file-pdf-regular.svg",
          },
          {
            name: this.$t("medicinePulInstructions.CreatingPersonalAccountForIE"),
            path: "/instructions/CreatingPersonalAccountForIE.pdf",
            filename: this.$t("medicinePulInstructions.CreatingPersonalAccountForIE"),
            icon: "./../assets/images/file-pdf-regular.svg",
          },
          {
            name: this.$t("medicinePulInstructions.CreatingPersonalAccountLegalEntity"),
            path: "/instructions/CreatingPersonalAccountLegalEntity.pdf",
            filename: this.$t("medicinePulInstructions.CreatingPersonalAccountLegalEntity"),
            icon: "./../assets/images/file-pdf-regular.svg",
          },
        ],
      };
    },
  };
  </script>
  <style scoped>
  .instructions_content {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #efefef;
    height: 100%;
  }
  
  .instructions {
    width: 60%;
    padding: 50px 0;
  }
  
  .docs a {
    display: flex;
    align-items: center;
    color: #000;
    text-decoration: none;
    margin: 20px 0;
    background-color: #fff;
    padding: 20px;
    border-radius: 15px;
    font-size: 20px;
  }
  
  .docs img {
    width: 20px;
    margin-right: 10px;
  }
  
  .docs a:hover {
    color: #1b27ff;
  }
  
  .instructions h2 {
    margin: 0;
    font-family: Roboto, sans-serif;
    font-weight: 500;
    font-size: 50px;
    color: transparent;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    background: linear-gradient(90deg, #ff143f 0%, #c600dd 49.97%, #1b27ff 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
  }
  </style>
  