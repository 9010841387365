<template>
  <div>
    <header>
      <div class="container">
        <div class="head" id="head">
          <div class="head-call">
            <img src="../assets/images/call.svg" v-on:click.prevent="showDropDown = !showDropDown" @click="clicked()"
              alt="" />
            <div class="show-phone" v-if="showDropDown">
              <a v-for="cont in contact" :key="cont.id" v-html="cont.title" :href="'tel:' + cont.title"></a>
            </div>
          </div>
          <div v-if="showDropDown" v-on:click.prevent="showDropDown = !showDropDown" class="call-wrapper"></div>
          <div class="dropdown-lang d_none">
            <select class="dropbtn custom-select" v-model="lang" @change="handleChange($event)">
              <option class="custom-select-opt" value="kz">KZ</option>
              <option class="custom-select-opt" value="en">EN</option>
              <option class="custom-select-opt" value="ru">RU</option>
            </select>
          </div>
          <div class="head-menu">
            <nav class="navbar navbar-expand-lg navbar-light">
              <div class="container-fluid">
                <button class="navbar-toggler" type="button" data-toggle="collapse" data-target=".navbar-collapse"
                  aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                  <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse" id="navbarNav">
                  <ul class="navbar-nav">
                    <li class="nav-item">
                      <button class="nav-link active btn btn-link dropdown-toggle" id="dropdownMenuButton"
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        {{ $t("nav.Bussiness") }}
                      </button>
                      <ul class="nav-link__sub" aria-labelledby="dropdownMenuButton">
                        <li>
                          <router-link to="/transport">{{ $t("bussiness.transport") }}
                          </router-link>
                        </li>
                        <li>
                          <router-link to="/property-main">{{
                            $t("bussiness.property")
                            }}</router-link>
                        </li>
                        <li>
                          <router-link to="/Cargo">{{
                            $t("bussiness.cargo")
                            }}</router-link>
                        </li>
                        <li>
                          <router-link to="/response">{{ $t("bussiness.response") }}
                          </router-link>
                        </li>
                        <li>
                          <router-link to="/HealthMain">{{
                            $t("bussiness.health")
                            }}</router-link>
                        </li>
                        <li>
                          <router-link to="/TemporaryMain">{{ $t("bussiness.temporary") }}
                          </router-link>
                        </li>
                      </ul>
                    </li>
                    <li class="nav-item">
                      <button class="nav-link active btn btn-link dropdown-toggle" id="dropdownMenuButton1"
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        {{ $t("nav.Individuals") }}
                      </button>
                      <ul class="nav-link__sub second" id="second-sub" aria-labelledby="dropdownMenuButton1">
                        <li>
                          <router-link to="/autoIndivid">{{ $t("individual.auto") }}
                          </router-link>
                        </li>
                        <li>
                          <router-link to="/healthIndivid">{{
                            $t("individual.health")
                            }}</router-link>
                        </li>
                        <li>
                          <router-link to="/houseIndivid">{{
                            $t("individual.property")
                            }}</router-link>
                        </li>
                        <li>
                          <router-link to="/touristIndivid">{{
                            $t("individual.tourist")
                            }}</router-link>
                        </li>
                        <li>
                          <router-link to="/typeIndivid">{{ $t("individual.type") }}
                          </router-link>
                        </li>
                        <!-- <li>
                          <router-link to="/buisnessIndivid">{{
                            $t("individual.bussiness")
                          }}</router-link>
                        </li> -->
                      </ul>
                    </li>
                    <li class="nav-item">
                      <router-link to="policy">
                        <button class="nav-link active btn btn-link dropdown-toggle" id="dropdownMenuButton2"
                          data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          {{ $t("nav.ServicesandPayments1") }}
                        </button>
                      </router-link>
                      <ul class="nav-link__sub" aria-labelledby="dropdownMenuButton2">
                        <li>
                          <a href="https://kupipolis.kz/ru/insurancecase">{{
                            $t("service.ins")
                            }}</a>
                        </li>
                        <li>
                          <a href="https://kupipolis.kz/ru">{{
                            $t("service.buy")
                            }}</a>
                        </li>
                        <li>
                          <a href="https://id.mkb.kz/#/esbd/checkInsurancePolicy">{{ $t("service.check") }}</a>
                        </li>
                        <li>
                          <a href="https://kupipolis.kz/ru/paypartial">{{
                            $t("service.payment")
                            }}</a>
                        </li>
                        <li>
                          <a href="https://kupipolis.kz/ru">{{
                            $t("service.calc")
                            }}</a>
                        </li>
                      </ul>
                    </li>
                    <li class="nav-item">
                      <router-link to="/jobAbout">
                        <p class="nav-link">{{ $t("nav.Vacancies") }}</p>
                      </router-link>
                    </li>
                    <li class="nav-item">
                      <!-- <router-link to="/AboutUs">
                                                <p class="nav-link">О компании</p>
                                            </router-link> -->
                      <button class="nav-link active btn btn-link dropdown-toggle" id="dropdownMenuButton2"
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        {{ $t("nav.AboutUs") }}
                      </button>
                      <ul class="nav-link__sub">
                        <li>
                          <router-link to="/about">{{
                            $t("about.AboutUs")
                            }}</router-link>
                        </li>
                        <li>
                          <router-link to="/documentsAbout">{{
                            $t("about.doc")
                            }}</router-link>
                        </li>
                        <li>
                          <router-link to="/pressAbout">{{
                            $t("about.press")
                            }}</router-link>
                        </li>
                        <li>
                          <router-link to="/compliance">{{
                            $t("about.compl")
                            }}</router-link>
                        </li>
                        <!-- <li>
                          <router-link to="/jobAbout">{{
                            $t("about.vac")
                            }}</router-link>
                        </li> -->
                        <li>
                          <router-link to="/Address">{{
                            $t("about.address")
                            }}</router-link>
                        </li>
                        <li>
                          <router-link to="/eventAbout">{{
                            $t("about.event")
                            }}</router-link>
                        </li>
                        <li>
                          <router-link to="/HolderAbout">{{
                            $t("about.ac")
                            }}</router-link>
                        </li>
                        <li>
                          <router-link to="/digitalAbout">{{
                            $t("about.dig")
                            }}</router-link>
                        </li>
                        <li>
                          <!-- <router-link to="/FAQabout">Другие вопросы</router-link> -->
                          <router-link to="/FAQabout">{{
                            $t("about.quest")
                            }}</router-link>
                        </li>
                      </ul>
                    </li>
                    <li class="nav-item">
                      <button class="nav-link active btn btn-link dropdown-toggle" id="dropdownMenuButton2"
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        {{ $t("nav.Contacts") }}
                      </button>
                      <ul class="nav-link__sub" aria-labelledby="dropdownMenuButton2">
                        <li>
                          <a v-for="cont in contact" :key="cont.id" v-html="cont.title" :href="'tel:' + cont.title"></a>
                        </li>
                      </ul>
                    </li>
                    <!-- <li class="nav-item">
                      <router-link to="/Address">
                        <p class="nav-link">{{ $t("nav.Address") }}</p>
                      </router-link>
                    </li> -->
                  </ul>
                </div>
              </div>
            </nav>
          </div>
          <div class="head-left">
            <h5 class="head-left-est m_none" v-bind:style="{ fontSize: fontSize + 'px' }">
              Est. 1997
            </h5>
            <router-link to="/">
              <img src="../assets/images/logo.svg" alt="" class="head-left-logo" />
            </router-link>
          </div>
          <div class="dropdown">
            <router-link to="/transport">
              <button class="dropbtn active" name="">
                {{ $t("nav.Bussiness") }}
              </button>
            </router-link>
            <div class="dropdown-content">
              <router-link to="/transport">{{
                $t("bussiness.transport")
                }}</router-link>
              <router-link to="/property-main">{{
                $t("bussiness.property")
                }}</router-link>
              <router-link to="/Cargo">{{ $t("bussiness.cargo") }}</router-link>
              <router-link to="/response">{{
                $t("bussiness.response")
                }}</router-link>
              <router-link to="/HealthMain">{{
                $t("bussiness.health")
                }}</router-link>
              <router-link to="/TemporaryMain">{{
                $t("bussiness.temporary")
                }}</router-link>
            </div>
          </div>
          <div class="dropdown">
            <router-link to="/autoIndivid">
              <button class="dropbtn">{{ $t("nav.Individuals") }}</button>
            </router-link>
            <div class="dropdown-content">
              <router-link to="/autoIndivid">{{
                $t("individual.auto")
                }}</router-link>
              <router-link to="/healthIndivid">{{
                $t("individual.health")
                }}</router-link>
              <router-link to="/houseIndivid">{{
                $t("individual.property")
                }}</router-link>
              <router-link to="/touristIndivid">{{
                $t("individual.tourist")
                }}</router-link>
              <router-link to="/typeIndivid">{{
                $t("individual.type")
                }}</router-link>
              <!-- <router-link to="/buisnessIndivid">{{
                $t("individual.bussiness")
              }}</router-link> -->
            </div>
          </div>
          <div class="dropdown">
            <router-link to="/policy">
              <button class="dropbtn">
                {{ $t("nav.ServicesandPayments") }}
              </button>
            </router-link>
            <div class="dropdown-content">
              <a href="https://kupipolis.kz/ru/insurancecase">{{
                $t("service.ins")
                }}</a>
              <a href="https://kupipolis.kz/ru">{{ $t("service.buy") }}</a>
              <a href="https://id.mkb.kz/#/esbd/checkInsurancePolicy">{{
                $t("service.check")
                }}</a>
              <a href="https://kupipolis.kz/ru/paypartial">{{
                $t("service.payment")
                }}</a>
              <a href="https://kupipolis.kz/ru">{{ $t("service.calc") }}</a>
            </div>
          </div>
          <div class="dropdown">
            <router-link to="/about"><button class="dropbtn">
                {{ $t("nav.AboutUs") }}
              </button></router-link>
            <div class="change-width">
              <div class="dropdown-content">
                <router-link to="/about">{{
                  $t("about.AboutUs")
                  }}</router-link>  
                <router-link to="/documentsAbout">{{
                  $t("about.doc")
                  }}</router-link>
                <router-link to="/pressAbout">{{
                  $t("about.press")
                  }}</router-link>
                <router-link to="/compliance">{{
                  $t("about.compl")
                  }}</router-link>
                <!-- <router-link to="/jobAbout">{{ $t("about.vac") }}</router-link> -->
                <router-link to="/address">{{ $t("about.address") }}</router-link>
                <router-link to="/eventAbout">{{
                  $t("about.event")
                  }}</router-link>
                <router-link to="/HolderAbout">{{
                  $t("about.ac")
                  }}</router-link>
                <router-link to="/digitalAbout">{{
                  $t("about.dig")
                  }}</router-link>
                <!-- <router-link to="/FAQabout">Другие вопросы</router-link> -->
                <router-link to="/FAQabout">
                  {{ $t("about.quest") }}</router-link>
              </div>
            </div>
          </div>
          <div class="dropdown">
            <router-link to="/jobAbout"><button class="dropbtn-change1">
                {{ $t("nav.Vacancies") }}
              </button></router-link>
          </div>
          <div class="dropdown">
            <button class="dropbtn-change">{{ $t("nav.Contacts") }}</button>
            <div class="dropdown-content">
              <a v-for="cont in contact" :key="cont.id" v-html="cont.title" :href="'tel:' + cont.title"></a>
            </div>
          </div>
          <!-- <div class="dropdown">
            <router-link to="/Address"><button class="dropbtn-change1">
                {{ $t("nav.Address") }}
              </button></router-link>
          </div> -->
          <div class="dropdown-desktop">
            <select class="dropbtn custom-select" style="border: none" v-model="lang" @change="handleChange($event)">
              <option class="custom-select-opt" value="kz">KZ</option>
              <option class="custom-select-opt" value="en">EN</option>
              <option class="custom-select-opt" value="ru">RU</option>
            </select>
          </div>
          <!-- <div class="home">
                        <a href="/"><img src="../assets/images/home.svg" alt=""></a>
                    </div> -->
          <button class="head-glasses">
            <!-- <a href="javascript://" onclick="letsee_toggle_panel()">Версия для слабовидящих</a> -->
            <a onclick="letsee_toggle_panel()"><img class="glass" src="../assets/images/glasses.svg" alt="" /></a>
            <!-- <img class="glass" src="../assets/images/glasses.svg" alt=""> -->
          </button>
        </div>
      </div>
    </header>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data: function () {
    const lang = localStorage.getItem("lang") || "ru";
    return {
      lang: lang,
      fontSize: 14,
      contact: [],
      showDropDown: false,
    };
  },
  created() {
    this.contacts();
  },
  methods: {
    handleChange(event) {
      localStorage.setItem("lang", event.target.value);
      window.location.reload();
    },
    contacts() {
      axios
        .get("V1/contacts")
        .then((response) => {
          // console.log("test",response.data.data)
          this.contact = response.data.data;
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
    clicked() {
      setTimeout(()=> (this.showDropDown = false), 3000)
    }
  },
};
</script>

<style lang="scss" scoped>
.dropdown-toggle::after {
  content: none;
}

@mixin fontStyle($family, $style, $weight, $size, $lheight, $color) {
  font-family: $family;
  font-style: normal;
  font-weight: $weight;
  font-size: $size;
  line-height: $lheight;
  color: $color;
}

@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

@mixin mobile {
  @media (min-width: 320px) and (max-width: 424px) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: 425px) and (max-width: 768px) {
    @content;
  }
}

@mixin tablet-1024 {
  @media (min-width: 768px) and (max-width: 1024px) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: 1024px) and (max-width: 1440px) {
    @content;
  }
}

@mixin desktop-max {
  @media (min-width: 1440px) {
    @content;
  }
}
.show-phone {
  position: absolute;
  font-size: 12px;
  line-height: 14px;
  // margin-left: -20%;
  background: #ffffff;
  border: 1px solid #acacac;
  border-radius: 5px;
  padding: 5px;
  a {
    color: #373737;
    font-weight: normal;
  }
  @include tablet() {
    // margin-left: -80px;
  }
}
.navbar-collapse {
  // background: linear-gradient(90deg, #DEDEDE 42%, #EFEFEF 40%);
  //changes
  // background: linear-gradient(90deg, #DEDEDE 35%, #EFEFEF 33%);
  background: #efefef;
  margin-top: 20px;
  bottom: 0;
  // @include tablet() {
  //     background: linear-gradient(90deg, #DEDEDE 38%, #EFEFEF 33%);
  // }
}
.container {
  padding-left: 0;
  padding-right: 0;
}
.dropdown-lang {
  z-index: 6;
  // margin-left: -25%;
  // margin-top: -5%;
  .custom-select {
    width: auto;
    border: none;
    @include fontStyle(Roboto, normal, 400, 14px, 14px, #373737);
    background: url("../assets/images/arrow-menu.png");
    background-repeat: no-repeat;
    background-position: right;
    padding-left: 0.75rem;
    padding-bottom: 6px;
    padding-right: 13px;
    &:focus {
      border: none;
    }
    &-opt {
      @include fontStyle(Roboto, normal, 400, 14px, 14px, #373737);
    }
  }
}
.call-wrapper {
  left: 0;
  bottom: 0;
  position: absolute;
  width: 100%;
  height: 100%;
}
.home {
  img {
    // width: 19px;
    margin-left: 5px;
    margin-right: 5px;
  }
  @include tablet-1024() {
    img {
      width: 19px;
    }
  }
  @include desktop() {
    img {
      width: 19px;
    }
  }
  @include mobile() {
    display: none;
  }
  @include tablet() {
    display: none;
  }
}
.head {
  margin-top: 20px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &-glasses {
    img {
      &:hover {
        transform: scale(1.1);
        transition: all 0.5s;
      }
    }
  }
  @include tablet-1024() {
    &-glasses {
      img {
        width: 20px;
      }
    }
  }
  &-menu {
    display: none;
    width: 30%;

    * html ul li {
      float: left;
    }

    * html ul li a {
      height: 1%;
    }
    .nav-link {
      @include fontStyle(Roboto, normal, 300, 14px, 16px, #959595);
      color: #959595 !important;
      padding-left: 20px;
      .active {
        color: #373737 !important;
        font-weight: 400;
      }
      &__sub {
        padding-left: 80px;
        @include fontStyle(Roboto, normal, 300, 12px, 14px, #959595);
        // top: 0;
        .second {
          top: -30px !important;
        }
      }
      &:hover {
        font-weight: 500;
        color: #373737 !important;
        transition: all 0.6s;
      }
      @media (max-width: 360px) {
        padding-left: 3% !important;
      }
      @include mobile() {
        //changes
        font-size: 12px !important;
        line-height: 14px;
        color: #373737 !important;
        font-weight: 600;
        margin-bottom: 0;
        padding-left: 8%;
        p {
          margin-bottom: 0;
        }
        &__sub {
          font-size: 10px;
          line-height: 20px;
          color: #373737;
          text-transform: uppercase;
          width: 175px;
          padding-left: 14px;
          // margin: 0 auto;
        }
        &:hover,
        &:focus {
          font-weight: 500;
          color: #373737 !important;
          background-color: #fff;
          width: 100%;
          border-radius: 0px;
          text-align: inherit;
          transition: all 0.6s;
        }
      }
      @include tablet() {
        font-size: 13px !important;
        line-height: 17px;
        color: #373737 !important;
        font-weight: 600;
        margin-bottom: 0;
        padding-left: 8%;
        p {
          margin-bottom: 0;
        }
        &__sub {
          font-size: 12px;
          line-height: 20px;
          color: #373737;
          text-transform: uppercase;
          width: 275px;
          padding-left: 14px;
          // margin: 0 auto;
        }
        &:hover,
        &:focus {
          font-weight: 500;
          color: #373737 !important;
          background-color: #fff;
          width: 100%;
          border-radius: 0px;
          text-align: inherit;
          transition: all 0.6s;
        }
      }
    }

    ul {
      // border-bottom: 1px solid #000;
      list-style: none;
      margin: 0;
      padding: 0;
      width: 100%;
      height: 100vh;
      position: relative;
      @include tablet() {
        height: 110vh;
      }
      li {
        background: #dedede;
        width: 164px;
        @include tablet() {
          width: 194px;
        }
        &:last-child {
          height: 100vh;
        }
        a {
          display: block;
          // border: 1px solid #000;
          border-bottom: 1px;
          padding: 5px 0px;
          text-decoration: none;
          color: #373737 !important;
          font-weight: 400;
        }

        :hover ul {
          display: block;
          transition: all 0.6s;
          // top: -30px;
        }
        // :hover .nav-link__sub .second {
        //     top: -30px !important;
        // }
        ul {
          display: none;
          left: 137px;
          // left: 99px;
          position: absolute;
          top: 0px;
          background-color: #efefef;
          li {
            background-color: #efefef;
            padding-top: 10px;
            @include tablet() {
              padding-top: 15px;
            }
          }
        }
      }
    }

    li:hover ul {
      display: block;
    }

    .navbar {
      z-index: 5;
      padding: 0;
      &-light,
      &-toggler {
        border: none;
        @include tablet() {
          // padding-right: 3%;
        }
      }

      &-expand-lg {
        .container-fluid {
          padding-top: 20px;
          flex-direction: row-reverse;
          @include tablet() {
            width: 100%;
            margin: 0 auto;
          }
        }
      }
    }
  }

  &-glasses {
    padding-top: 15px;
    border: none;
    cursor: pointer;
    background-color: transparent;
  }

  &-left {
    z-index: 111;

    &-est {
      @include fontStyle(Roboto, normal, 300, 14px, 136.69%, #000000);
      background: linear-gradient(
        90deg,
        #ff143f 0%,
        #c600dd 49.97%,
        #1b27ff 100%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
    }

    &-logo {
      // max-width: 90px;
      width: 202px;
    }
  }

  &-call {
    display: none;
  }

  .dropdown {
    position: relative;
    display: inline-block;
    padding-top: 20px;
    &:hover {
      .dropdown-content {
        display: block;
        z-index: 223;
        cursor: pointer;
      }

      .dropbtn {
        transition: 0.4s;
        background-color: #f1f1f1;
        // width: 192px;
      }
    }

    .dropbtn {
      @include fontStyle(Roboto, normal, 400, 16px, 136.69%, #373737);
      background-color: #ffffff;
      text-transform: uppercase;
      padding: 10px;
      border: none;
      &-change {
        text-transform: none;
        @include fontStyle(Roboto, normal, 400, 16px, 136.69%, #373737);
        background-color: #ffffff;
        padding: 13px;
        border: none;
        background: url("../assets/images/arrow-menu.png");
        background-repeat: no-repeat;
        background-position: right;
        &:hover {
          background-color: #f1f1f1;
          transition: all 0.6s;
        }
      }
      &-change1 {
        text-transform: none;
        @include fontStyle(Roboto, normal, 400, 16px, 136.69%, #373737);
        background-color: #ffffff;
        padding: 13px;
        border: none;
        &:hover {
          background-color: #f1f1f1;
          transition: all 0.6s;
          box-shadow: -5px 0px 0px 0px #f1f1f1, 5px 0px 0px 0px #f1f1f1;
        }
      }
      .custom-select {
        :focus {
          box-shadow: none;
        }
      }
      option {
        @include fontStyle(Roboto, normal, 400, 14px, 14px, #373737);
      }

      &:focus {
        color: #000000;
      }
    }

    &-content {
      display: none;
      position: absolute;
      background-color: #ffffff;
      border: 1px solid #f1f1f1;
      width: clamp(200px, 118%, 420px);
      box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
      z-index: 1;
      border-radius: 5px;
      a {
        &:hover {
          background-color: #ffffff !important;
          font-weight: 500;
          transition: all 0.6s;
        }
      }
      &::before {
        position: absolute;
        content: "";
        height: 10px;
        width: 10px;
        background: #ffffff;
        left: 20px;
        top: -5px;
        transform: rotate(45deg);
      }

      a {
        @include fontStyle(Roboto, normal, 300, 16px, 18px, #373737);
        padding: 6px 4px;
        text-decoration: none;
        display: block;

        &:hover {
          background-color: #ddd;
        }
      }
    }

    .active {
      color: #000000;
      font-weight: 400;
    }

    &-desktop {
      padding-top: 15px;
      .custom-select {
        width: auto;
        // border: none;
        @include fontStyle(Roboto, normal, 300, 18px, 21px, #373737);
        background: url("../assets/images/arrow-menu.png");
        background-repeat: no-repeat;
        background-position: right;
        padding-left: 0.75rem;
        padding-bottom: 0;
        padding-right: 13px;
        option {
          @include fontStyle(Roboto, normal, 300, 14px, 14px, #373737);
          width: 200px;
        }
        @include tablet-1024() {
          font-size: 11px;
        }
        &:hover {
          background-color: #f1f1f1;
          // outline: 1px solid #f1f1f1;
          // height: 30px;
          // outline-width: 30px;
          
          box-shadow: -25px 0px 0px 0px #f1f1f1, 25px 0px 0px 0px #f1f1f1;
          // outline-width: 100px;
          height: 45px;
          transition: all 0.6s;
        }
        &:focus,
        &:focus-visible {
          border: none !important;
          outline: none !important;
        }
      }
    }
  }

  @include mobile() {
    &-left {
      width: 43%;
      margin: 0 auto;
      text-align: center;
      margin-left: -23%;
      &-logo {
        min-width: 79px;
        max-width: 96px;
      }
      &-est {
        font-size: 10px !important;
      }
    }
    &-call {
      display: inline-block;
      z-index: 111;
    }

    .d-none {
      display: inline !important;
    }

    &-menu {
      .navbar {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        background: #ffffff;
        padding: 0;
        // background: linear-gradient(90deg, #FFC0CB 50%, #00FFFF 50%);
      }
      ul {
        li {
          a {
            padding: 0;
            color: #595959;
            &:active {
              color: linear-gradient(
                90deg,
                #ff143f 0%,
                #c600dd 49.97%,
                #1b27ff 100%
              );
            }
          }
        }
      }
    }

    .dropdown {
      display: none;
      &-desktop {
        display: none;
      }
      &-lang {
        .custom-select {
          padding-bottom: 2px;
          padding-right: 13px;
          margin-right: 6px;
        }
      }
    }

    &-menu {
      display: block;
    }

    &-glasses {
      .glass {
        display: none;
      }

      a {
        display: block;
      }
    }
  }

  @include tablet() {
    &-left {
      width: 42%;
      margin-left: -14% !important;
      margin: 0 auto;
      text-align: center;
      &-logo {
        min-width: 79px;
        max-width: 136px;
      }

      &-est {
        font-size: 10px;
      }
    }

    &-call {
      display: inline-block;
      z-index: 111;
      // position: absolute;
      // right: 0;
      width: 6%;
      padding-bottom: 10px;
      // margin-right: -10px;
    }

    &-menu {
      display: block;
      ul {
        li {
          a {
            padding: 0;
          }
        }
      }

      .navbar {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        background: #ffffff;
      }
    }

    .dropdown {
      display: none;

      &-desktop {
        display: none;
      }
      &-lang {
        padding-bottom: 8px;
        .custom-select {
          margin-right: 10px;
        }
      }
    }

    &-glasses {
      .glass {
        display: none;
      }

      a {
        display: block;
      }
    }
  }

  @include tablet-1024() {
    .dropdown {
      .dropbtn,
      .dropbtn-change,
      .dropbtn-change1 {
        font-size: 8px;
      }

      &-content {
        a {
          font-size: 9px;
        }
      }
    }
    &-left {
      &-logo {
        width: 75px;
      }
      &-est {
        font-size: 10px !important;
      }
    }
  }

  @include desktop() {
    &-left {
      &-logo {
        // width: 160px;
      }
    }
    .dropdown {
      .dropbtn {
        font-size: 14px;
      }

      &-content {
        a {
          font-size: 12px;
          width: 100%;
        }
      }
    }
  }

  @include desktop-max() {
    .dropdown {
      .dropbtn {
        font-size: 16px;
        line-height: 22px;
        font-weight: 400;
      }

      &-content {
        width: clamp(283px, 140%, 430px);
        padding: 5px;
        a {
          font-size: 16px;
        }
      }
    }
  }

  .d-none {
    display: none;
  }
}
</style>
