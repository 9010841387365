<template>
  <div>
    <head-bottom />
    <div class="temporary">
      <div class="temporary-about">
        <div class="container">
          <div class="back">
            <div class="temporary-about__left">
              <p class="temporary-about__left__title" v-html="temp[0].title"></p>
              <p class="temporary-about__left__sub" v-html="temp[0].desc"></p>
              <div class="temporary-about__left__btn m_none">
                <polis-button :p_name="p_name" />
                <!-- <a href="https://kupipolis.kz/ru/paypartial"><button>Оформить полис</button></a> -->
              </div>
            </div>
            <div class="temporary-about__right">
              <img :src="cdn + temp[0].icon" alt="" />
              <div class="temporary-about__left__btn-mob d_none">
                <polis-button :p_name="p_name" />
                <!-- <a href="https://kupipolis.kz/ru/paypartial"><button>Оформить полис</button></a> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="temporary-words">
        <div class="temporary-words__card" v-for="tm in temp.slice(1, 5)" :key="tm">
          <p class="temporary-words__card__title" v-html="tm.title"></p>
          <p class="temporary-words__card__sub" v-html="tm.desc"></p>
          <div v-if="tm.icon" class="temporary-words__btn">
                <a href="/medicineRespPul"><button>{{
                    $t("buttons.lmore") }}</button></a>
          </div>
          <img v-if="tm.icon && lang == 'ru'" src="../assets/images/med_ins_ru.png" />
          <img v-if="tm.icon && lang == 'en'" src="../assets/images/med_ins_eng.png" />
          <img v-if="tm.icon && lang == 'kz'" src="../assets/images/med_ins_kz.png" />
        </div>
        <div class="temporary-words__btn">
          <time-btn :p_name="p_name" />
        
          <!-- <a href="https://kupipolis.kz/ru/paypartial"><button>Оформить полис</button></a> -->
        </div>
      </div>
      <div class="temporary-accordion">
        <accordion />
      </div>
      <question :p_name="p_name" />
      <SliderMain1 class="m_none" />
      <slider-main-mobile-2 class="d_none" />
    </div>
  </div>
</template>

<script>
import Accordion from "../components/accordion.vue";
import HeadBottom from "../components/HeadBottom.vue";
import Question from "../components/Question.vue";
import timeBtn from "../components/timeBtn.vue";
import PolisButton from "@/components/PolisButton.vue";
import SliderMain1 from "@/components/SliderMain1-1.vue";
import SliderMainMobile2 from "@/components/SliderMainMobile2.vue";
import axios from "axios";
import { mapState } from "vuex";

export default {
  name: "TemporaryMain",
  components: {
    Accordion,
    HeadBottom,
    Question,
    PolisButton,
    timeBtn,
    SliderMain1,
    SliderMainMobile2,
  },
  data() {
    return {
      temp: [],
      errors: [],
      // p_name: 'Страхование воздушного транспорта',
      p_name: "Вмененное страхование",
      lang: 'ru'
    };
  },
  created() {
    axios
      .get("V1/business/time")
      .then((response) => {
        this.temp = response.data.data;
        this.lang = localStorage.getItem('lang') || 'ru';
      })
      .catch((e) => {
        this.errors.push(e);
      });
  },
  computed: {
    ...mapState(["cdn"]),
  },
};
</script>

<style lang="scss" scoped>
@mixin fontStyle($family, $style, $weight, $size, $lheight, $color) {
  font-family: $family;
  font-style: normal;
  font-weight: $weight;
  font-size: $size;
  line-height: $lheight;
  color: $color;
}
@mixin mobile {
  @media (min-width: 320px) and (max-width: 425px) {
    @content;
  }
}
@mixin tablet {
  @media (min-width: 425px) and (max-width: 768px) {
    @content;
  }
}
@mixin tablet-1024 {
  @media (min-width: 768px) and (max-width: 1024px) {
    @content;
  }
}
@mixin desktop {
  @media (min-width: 1024px) and (max-width: 1440px) {
    @content;
  }
}
@mixin desktop-max {
  @media (min-width: 1620px) and (max-width: 2560px) {
    @content;
  }
}
.d_none {
  @include mobile() {
    padding-bottom: 25px !important;
  }
  @include tablet() {
    padding-bottom: 35px !important;
  }
}
.temporary {
  &-about {
    background: #efefef;
    .back {
      display: flex;
    }
    &__left {
      &__title {
        padding-top: 80px;
        padding-bottom: 17px;
        @include fontStyle(Roboto, normal, 500, 36px, 43px, transparent);
        letter-spacing: 0.03em;
        text-transform: uppercase;
        background: linear-gradient(
          90deg,
          #ff143f 0%,
          #c600dd 49.97%,
          #1b27ff 100%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
      }
      &__sub {
        @include fontStyle(Roboto, normal, 400, 23px, 36px, #373737);
        text-transform: uppercase;
      }
      &__btn,
      &__btn-mob {
        margin-top: 40px;
        margin-bottom: 80px;
        button {
          background: #ffffff;
          border-radius: 15px;
          width: 200px;
          height: 70px;
          border: none;
          color: #373737;
          @include fontStyle(Roboto, normal, 600, 18px, 25px, #373737);
          &:hover {
            background: linear-gradient(
              90deg,
              #ff4466 0%,
              #be31ce 49.97%,
              #3641ff 100%
            );
            color: #ffffff;
          }
        }
      }
    }
    &__right {
      display: flex;
      align-items: center;
    }
  }
  &-words {
    margin: 0 auto;
    max-width: 1320px;
    width: 80%;
    &__card {
      margin-top: 85px;
      align-items: center;
      &__title {
        @include fontStyle(Roboto, normal, 500, 28px, 34px, #373737);
        letter-spacing: 0.03em;
        text-transform: uppercase;
        margin-bottom: 0;
      }
      &__sub {
        @include fontStyle(Roboto, normal, 300, 18px, 22px, #373737);
        padding-top: 10px;
        margin-bottom: 0;
      }
      img{
        width: 90%;
      }
    }
    &__btn {
      margin: 85px 0 85px 0;
      button {
        width: 200px;
        height: 70px;
        border-radius: 15px;
        @include fontStyle(Roboto, normal, 600, 18px, 25px, #373737);
        border: none;
        background: #efefef;
        &:hover {
          background: linear-gradient(
            90deg,
            #ff4466 0%,
            #be31ce 49.97%,
            #3641ff 100%
          );
          color: #ffffff;
        }
      }
    }
  }
  &-accordion {
    margin-top: 90px;
    margin-bottom: 90px;
  }
  @include mobile() {
    &-about {
      padding-bottom: 15px;
    }
    &-accordion {
      margin-top: 0px;
      margin-bottom: 0px;
    }
    padding-top: 0px;
    padding-bottom: 0px;
    &-about {
      .back {
        flex-wrap: wrap;
      }
      &__left {
        margin: 0 auto;
        &__title {
          font-size: 14px;
          padding-top: 17px;
          padding-bottom: 0px;
          margin-bottom: 0;
        }
        &__sub {
          padding-top: 0;
          padding-bottom: 0;
          font-size: 12px;
          line-height: 14px;
        }
        &__btn-mob {
          margin-top: 20px;
          margin-bottom: 0px;
          text-align: center;
          button {
            width: 130px;
            height: 40px;
            font-size: 10px;
            border-radius: 7px;
          }
        }
      }
      &__right {
        display: block;
        text-align: center;
        img {
          width: 75%;
          margin: 0 auto;
          display: flex;
        }
      }
    }
    &-words {
      width: 90%;
      &__card {
        margin-top: 30px;
        &__title {
          font-size: 14px;
          line-height: 16px;
          padding-bottom: 10px;
        }
        &__sub {
          font-size: 12px;
          line-height: 16px;
          padding-top: 0px;
        }
      }
      &__btn {
        margin: 35px 0 35px 0;
        // text-align: center;
        button {
          width: 130px;
          height: 50px;
          border-radius: 7px;
          font-size: 12px;
        }
      }
    }
  }
  @include tablet() {
    padding-top: 0px;
    padding-bottom: 30px;
    &-about {
      .back {
        flex-wrap: wrap;
      }
      &__left {
        margin: 0 auto;
        &__title {
          text-align: center;
          font-size: 16px;
          padding-top: 20px;
          margin-bottom: 0 !important;
        }
        &__sub {
          padding-top: 0;
          padding-bottom: 0;
          font-size: 12px;
          line-height: 16px;
        }
        &__btn {
          margin-top: 20px;
          margin-bottom: 20px;
          text-align: center;
          &-mob {
            margin-top: 20px;
            margin-bottom: 0px;
          }
          button {
            width: 130px;
            height: 40px;
            font-size: 10px;
            border-radius: 7px;
          }
        }
      }
      &__right {
        display: block;
        text-align: center;
        img {
          width: 75%;
          margin: 0 auto;
          display: flex;
        }
      }
    }
    &-words {
      &__card {
        margin-top: 45px;
        &__title {
          font-size: 14px;
          line-height: 16px;
          padding-bottom: 15px;
        }
        &__sub {
          font-size: 12px;
          line-height: 16px;
          padding-top: 0px;
        }
      }
      &__btn {
        margin: 35px 0 35px 0;
        button {
          width: 130px;
          height: 50px;
          border-radius: 7px;
          font-size: 12px;
        }
      }
    }
    &-accordion {
      margin-top: 35px;
      margin-bottom: 40px;
    }
  }
  @include tablet-1024() {
    &-about {
      .back {
        flex-wrap: wrap;
      }
      &__left {
        margin: 0 auto;
        &__title {
          text-align: center;
          font-size: 16px;
          padding-top: 20px;
          padding-bottom: 10px;
        }
        &__sub {
          font-size: 12px;
          line-height: 16px;
        }
        &__btn {
          margin-top: 20px;
          margin-bottom: 20px;
          text-align: center;
          button {
            width: 130px;
            height: 40px;
            font-size: 10px;
            border-radius: 7px;
          }
        }
      }
      &__right {
        margin: 0 auto;
        text-align: center;
        justify-content: center;
        img {
          width: 50%;
        }
      }
    }
    &-words {
      &__card {
        margin-top: 45px;
        &__title {
          font-size: 14px;
          line-height: 16px;
        }
        &__sub {
          font-size: 12px;
          line-height: 16px;
          padding-top: 0px;
        }
      }
      &__btn {
        margin: 35px 0 35px 0;
        // text-align: center;
        button {
          width: 130px;
          height: 50px;
          border-radius: 7px;
          font-size: 12px;
        }
      }
    }
  }
  @include desktop() {
    &-about {
      &__left {
        &__title {
          padding-top: 50px;
          padding-bottom: 20px;
          font-size: 30px;
          line-height: 33px;
        }
        &__sub {
          font-size: 18px;
          line-height: 22px;
        }
        &__btn {
          margin-top: 30px;
          margin-bottom: 30px;
        }
      }
      &__right {
        img {
          width: 100%;
        }
      }
    }
  }
}
</style>
